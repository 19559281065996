import React, { useEffect } from "react"
import AttendanceBox from "../AttendanceBox/AttendanceBox"
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from "../../reduxMap"
import { navigate } from "gatsby"
import axios from "axios"
import Container from "../Container/Container"
import { Power } from "react-feather"

import classes from "./dashboard.module.scss"
import { login } from "../../util"

let Dashboard = ({ user, setLoading, stopLoading, openSnackbar, logout }) => {
  const [data, setData] = React.useState({})
  const [todayTimetable, setTodayTimetable] = React.useState({})

  async function getTodayTimetable() {
    let payload = {
      session: user.session,
    }

    await axios
      .post("https://etcool-api.herokuapp.com/getTodayTimetable", payload)
      .then(res => res.data)
      .then(async result => {
        if (result.success === true) {
          setTodayTimetable(result.data)
        } else {
          if (result.loggedOut) {
            if (!user.username || !user.password) {
              logout()
            } else {
              await login({
                username: user.username,
                password: user.password,
                setUser,
                setLoading,
                stopLoading,
                openSnackbar,
              })
            }
          }

          openSnackbar({
            snackbarText: result.message,
            snackbarType: "error",
          })
        }
      })
      .catch(error => {
        // console.log({ error })
        logout()
      })
  }

  async function getAttendance() {
    let payload = {
      session: user.session,
    }

    setLoading("Hey dude, please wait while I fetch your data...")

    await axios
      .post("https://etcool-api.herokuapp.com/getAttendance", payload)
      .then(res => res.data)
      .then(async result => {
        if (result.success === true) {
          setData(result.data)
          stopLoading()
        } else {
          if (result.loggedOut) {
            if (!user.username || !user.password) {
              logout()
            } else {
              await login({
                username: user.username,
                password: user.password,
                setUser,
                setLoading,
                stopLoading,
                openSnackbar,
              })
            }
          }

          openSnackbar({
            snackbarText: result.message,
            snackbarType: "error",
          })
        }
      })
      .catch(error => {
        // console.log({ error })
        logout()
      })
  }

  useEffect(() => {
    if (!user || !user.session) {
      setLoading("Dude, you ain't logged in...")
      navigate("/login")
    } else {
      stopLoading()
    }
  }, [user])

  useEffect(() => {
    if (user.session) {
      getAttendance()
      getTodayTimetable()
    }
  }, [])

  return (
    <div style={{ minHeight: "100vh" }}>
      <button className={classes.logout} onClick={logout}>
        <Power size={22} />
      </button>
      {(data && Object.keys(data).length > 0 && (
        <>
          <div className={classes.nameContainer}>
            <span>i am </span>
            {data.name}
          </div>
          <AttendanceBox
            data={data.attendance[0]}
            todayTimetable={todayTimetable.timetable}
          />
          {/* <AttendanceBox data={[{ subject: "Subject 1", percentage: 80.0 }]} /> */}
        </>
      )) || (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            flexDirection: "column",
            height: "100%",
          }}
        >
          some error occurred
        </div>
      )}
    </div>
  )
}

Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)

export default Dashboard
