import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import Layout from "../components/Layout"
import Dashboard from "../components/Dashboard/Dashboard"

let LayoutDashboardPage = ({ user }) => {
  return (
    <Layout>
      <Dashboard />
    </Layout>
  )
}

export default LayoutDashboardPage
